import React from "react";
import './adblue.css';
import { Droplets, ArrowRight, Shield, Leaf, Gauge } from 'lucide-react';

const Adblue = () => {
    return (
        <div className="container">
          <div className="content-wrapper">
            <div className="grid-layout">
              {/* Left Content */}
              <div className="left-content">
                <div className="header-section">
                  <div className="tag">
                    <Droplets className="icon" />
                    <span className="tag-text">EMISSIONS SOLUTION</span>
                  </div>
                  <h1 className="title">ADBLUE® SOLUTION IN UGANDA</h1>
                  <p className="description">
                    Reduce your vehicle's emissions and meet environmental standards with our high-quality AdBlue® diesel exhaust fluid. Our solution helps convert harmful NOx emissions into harmless nitrogen and water vapor. Available in Kampala and across Uganda.
                  </p>
                </div>
    
                {/* Features */}
                <div className="features">
                  <div className="feature">
                    <Shield className="small-icon icon" />
                    <span className="feature-text">ISO Certified</span>
                  </div>
                  <div className="feature">
                    <Leaf className="small-icon icon" />
                    <span className="feature-text">Eco-Friendly</span>
                  </div>
                  <div className="feature">
                    <Gauge className="small-icon icon" />
                    <span className="feature-text">Performance Boost</span>
                  </div>
                </div>
    
                <button className="cta-button">
                    <a href="/services" style={{textDecoration: 'none', color: 'white'}}>GET IN TOUCH</a>
                  <ArrowRight className="icon" />
                </button>
              </div>
    
              {/* Right Image */}
              <div className="image-container">
                <div className="image-background"></div>
                <div className="image-wrapper">
                  <img
                    src="https://th.bing.com/th/id/R.de83fddf1c093a4f2a0777b8428d2961?rik=lc3t9bEY2GSO6A&pid=ImgRaw&r=0"
                    alt="AdBlue solution for diesel vehicles in Uganda"
                    className="feature-image"
                  />
                  <div className="image-overlay"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
};

export default Adblue;
